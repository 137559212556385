import { useEffect, useState } from "react"
import Logo from "./Logo.js"
import Search from "./Search.js"
import { client } from '../src/App'
import NewsItem from "./NewsItem.js"
import { camelize } from "../functions/utils.js"
import AnchorMenu from "./AnchorMenu.js"

const Homepage = () => {
    const [ data, setData ] = useState()

    const [ anchorData, setAnchorData ] = useState([])
    const [ activeAnchor, setActiveAnchor ] = useState('')

    const fetcher = async () => {
        try {
          const entryItems = await client.getEntries({ content_type: 'news', include: 1, limit: 20 })
          const modules = {
            content: [],
          }
          entryItems.items.forEach((item) => {
            const name = item.fields.title
            const subSections = []
            item.fields.contentBlock.forEach(subItem => {
                if (subItem.fields) {
                    subSections.push({
                        name: subItem.fields.title,
                        id: camelize(subItem.fields.title),
                    })
                }
            })
            modules.content.push({
                name,
                id: camelize(name),
                subSections
            })
          })
          setAnchorData(modules.content)
          setData(entryItems.items)
        } catch (err) {
          console.log(err)
        }
    }
    
    useEffect(() => {
        fetcher()
    }, [])

    useEffect(() => {
        if (data) {
          const observerOptions = {
            rootMargin: '50px 0px 50px 0px',
            threshold: [0.7]
          }
          const observer = new IntersectionObserver(observerCallback, observerOptions)
          function observerCallback(entries) {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (window.scrollY !== 0) setActiveAnchor(entry.target.id)
              }
            })
          }
          setTimeout(() => {
            const collection = document.querySelectorAll('article')
            collection.forEach((i) => {
              if (i) {
                observer.observe(i)
              }
            })
          }, 1000)
        }
      }, [data])

    return (
        <div className="homepage" style={{width: '70%'}}>
            <Logo id='2' />
            <div id="search-widget">
                <Search notModal={true} />
            </div>
            <div className="homepage-content">
                <section>
                    <h2 className="content_h2">News</h2>
                    {data && data.map((item, i) => <NewsItem activeAnchor={activeAnchor} item={item} key={i} /> )}
                </section>
                <AnchorMenu data={anchorData} activeAnchor={activeAnchor} setActiveAnchor={setActiveAnchor} />
            </div>
        </div>
    )
}

export default Homepage