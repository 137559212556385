
import { useEffect, useRef, useState } from "react"
import { camelize, dateStringToUSDate } from "../functions/utils.js"
import News from "../modules/News.js"
import EditDate from "./EditDate.js"

const NewsItem = ({ item, activeAnchor }) => {
  const [isOpen, setOpen] = useState(false)
  const headerRef = useRef(null)
  const onHeaderClick = () => {
    if (headerRef && !isOpen) {
        setTimeout(() => {
            headerRef.current.scrollIntoView({ behavior: "smooth" })
        }, 80)
    }
    setOpen(!isOpen)
  }

  useEffect(() => {
    if (item.fields.contentBlock) {
      const dict = []
      item.fields.contentBlock.forEach(block => {
        dict.push(camelize(block.fields.title))
        setNestedItems(dict)
      })
    }
  }, [])

  const [nestedItems, setNestedItems] = useState([])
  useEffect(() => {
    if (activeAnchor === camelize(item.fields.title)) {
      setOpen(true)
    }
    if (nestedItems.includes(activeAnchor)) {
      setOpen(true)
    }
  }, [activeAnchor])
  
  return (
    <article className="news-item block-style" id={camelize(item.fields.title)}>
        <button className="news-item__header" ref={headerRef} onClick={onHeaderClick}>
           <span className="news-item__icon">{isOpen ? '-': '+'}</span>
           <h3 className="content_h3" style={{marginTop: 0}}>{item.fields.title}</h3>
        </button>
        <div className={`news-item__content ${isOpen ? 'open': ''}`}>
            <EditDate date={dateStringToUSDate(item.sys.updatedAt)} />
            {item.fields.contentBlock && item.fields.contentBlock.map((block, i) => <News data={block.fields} key={i} />)}
        </div>
    </article>
  )
}

export default NewsItem